@use '@angular/material' as mat;
@import '../../../asset-details-live-container/components/current-activity/current-activity.theme';
@import '../../../shared/components/expanding-details/expanding-details.theme';
@import '../../../shared/components/nearby-assets-list/nearby-assets-list.theme';

@mixin mobile-location-container($theme) {
    $primary:       map-get($theme, primary);
    $accent:        map-get($theme, accent);
    $warn:          map-get($theme, warn);
    $background:    map-get($theme, background);
    $foreground:    map-get($theme, foreground);

    @include current-activity-theme($theme);
    @include expanding-details-theme($theme);
    @include nearby-assets-list-theme($theme);

    .mobile-map-pane .map-container .leaflet-container {
        border-top: 2px ridge mat-color($primary, lighter);
    }

}
