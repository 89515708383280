@import "@angular/material/theming";

@mixin driver-list-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .icon {
        margin-right: 1em;
    }

    .value {
        margin-bottom: .5em;
    }

    .tooltip-icon {
        margin-left: 0.25em;

        mat-icon {
            color: mat.get-color-from-palette($accent, default);
        }
    }

}