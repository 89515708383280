@use '@angular/material' as mat;
@mixin driver-details-theme($theme) {
  $primary:    map-get($theme, primary);
  $warn:       map-get($theme, warn);
  $accent:     map-get($theme, accent);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  @include current-activity-theme($theme);
  @include expanding-details-theme($theme);

  .driver-details-container {
    cursor: default;

    .mat-icon {
      color: mat.get-color-from-palette($primary, default);
    }
  }
}
