@use '@angular/material' as mat;
@mixin expanding-details-theme($theme) {
  $primary:    map-get($theme, primary);
  $warn:       map-get($theme, warn);
  $accent:     map-get($theme, accent);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .expanding-details-container {
    .disabled {
      pointer-events: none;

      .mat-icon.expand-icon {
        color: mat.get-color-from-palette($primary, 500);
        position: relative;
        top: .25em;
      }
    }

    .bottom-border {
      border-bottom: 1px solid mat.get-color-from-palette($primary, lighter);
    }

    .mat-button {
      .activity-value {
        font-weight: bold;
        @include mat.typography-level($zonar-default-typography, body-1);
      }
    }

    .mat-button:hover {
      background: mat.get-color-from-palette($accent, 100);
    }

    .tooltip-icon {
      margin-left: 0.25em;

      mat-icon {
        color: mat.get-color-from-palette($accent, default);
      }
    }

    .detail-title {
      @include mat.typography-level($zonar-default-typography, body-1);
      color: mat.get-color-from-palette($primary, default);
      text-transform: uppercase;
    }

    .detail-value {
      @include mat.typography-level($zonar-default-typography, body-2);
      margin-left: 1em;
      text-align: right;

      .radar-fill {
        fill: mat.get-color-from-palette($accent, default);
      }
    }

    .details-list li:not(:last-child) {
      border-bottom: 1px dashed mat.get-color-from-palette($primary, default);
    }
  }
}
