@use '@angular/material' as mat;
@mixin map-content-selector-theme($theme) {
  $primary:       map-get($theme, primary);
  $accent:        map-get($theme, accent);
  $warn:          map-get($theme, warn);
  $background:    map-get($theme, background);
  $foreground:    map-get($theme, foreground);

  .content-selector-container {
    .map-content-selector {
      @include mat.typography-level($zonar-default-typography, body-2);
      background: mat.get-color-from-palette($primary, 200);
      border: 1px solid mat.get-color-from-palette($primary, 400);
      border-left: 0;
      border-right: 0;
      cursor: pointer;
    }
  }
}
