@use '@angular/material' as mat;
// pattern library
@import '@zonar-ui/material/zonar-ui-material.theme';
@import '@zonar-ui/core/zonar-ui-core.theme';
@import '@zonar-ui/notifications/zonar-ui-notifications.theme';
@import '@zonar-ui/sidenav/zonar-ui-sidenav.theme';
@import '@zonar-ui/filter/zonar-ui-filter.theme';
@import '@zonar-ui/asset-properties/zonar-ui-asset-properties.theme';

// application themes
@import '../app/modules/location/containers/locations/locations.theme';
@import '../app/app.theme';

@mixin apply-zonar-theme($theme) {
  @include mat.all-component-themes($theme);
  @include zonar-ui-sidenav-theme($theme);
  @include zonar-ui-core-theme($theme);
  @include zonar-ui-notifications-theme($theme);
  @include zonar-ui-filter-theme($theme);
  @include zonar-ui-asset-properties-theme($theme);
  @include locations-page-theme($theme);
  @include app-theme($theme);
}

.zonar-default-theme {
  @include apply-zonar-theme($zonar-default-theme);
}

.zonar-dark-theme {
  @include apply-zonar-theme($zonar-dark-theme);
  background-color: black;
}

$vdo-red: #DB4F60;
$vdo-yellow: #F3C835;
.zonar-vdo-theme {
  @include apply-zonar-theme($zonar-vdo-theme);
}

// Fix for the zui-searchable-dropdown, desktop and mobile - the placeholder text should not overlap the search icon
// TODO - Move this into the pattern library
.cdk-overlay-pane {
  .mat-select-search-inner {
    .mat-select-search-input {
      text-indent: 1em;
    }
  }
}

/* Custom sidenav style */
.sidenav-container {
  max-width: 100vw;
}

// fix large font in sidenav company selector popup
.mat-option.autocomplete-item {
  font-size: 1rem;
}

.app-container-mobile{
  position: fixed; // position=fixed will prevent users from horizontally scrolling content off screen on mobile devices
  top: 0;

  zui-sidenav {
    height: 100dvh;

    .sidenav-menu-icon {
      background: none;
    }
    
    .sidenav-container {
      height: 100dvh;

      .header-bar-sidenav-toolbar {
        height: auto;
        z-index: 0;
      }

      .mobile-layout-container {
        position: fixed;
        height: 100dvh;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
      
        .map-pane {
          height: 100%;
        }
      
        @supports (display: flex) {
          .map-pane {
            height: 0;
            flex: .9 0 auto;
          }
      
          .map-container {
            flex: 1 0 auto;
            height: 100%;
          }
        }
      }
    }
  }


  // Styling for the zui-searchable-dropdown, mobile
  .cdk-overlay-pane {
    .mat-select-panel-wrap {
      margin-left: unset !important;
    }

    .mat-select-panel {
      min-width: 85vw !important;
      margin-left: 1em;
    }

    .mat-icon#zonar-glass-icon {
      left: 1.2em !important;
    }
  }
}

// fix for material buttons
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button,
.mat-button-base,
.mat-button-wrapper {
  padding: 0;
  margin: 0;
  line-height: unset;
}


