#error-section {
    margin:0px; 
    background: #5b6770; 
    font-family: 'Open Sans', sans-serif; font-size: 16px;
    position: absolute; 
    top: 0;
    width: 100%;
    height: 100%;

    #error-box {
        background: #FFF;
        border-radius: 3px; 
        width: 408px; 
        padding: 36px; 
        color: #5B6770; 
        margin: 48px auto 32px; 
        box-shadow: 0 20px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }

    #error-box div{
        width: 100%; 
        text-align: center; 
        margin-bottom: 48px;
    }
    #error-box h3 {
        font-size: 28px; 
        margin-top: 0
    }
    #error-box #contact-section {
        margin-bottom: 0;
    }

    #error-box a {
        color: #56A3E6;
    }

    #footer {
        text-align: center; 
        font-size: 14px;
    }

    #footer p{
        color: #E2E4E6; 
        margin-top: 8px;
    }

    #footer a {
        color: #DEEDFA;
        text-decoration: none;
    }

    #footer a:hover {
        text-decoration: underline;
    }
}

@media (max-width: 580px){
    #error-box {
        width: calc(100% - 25px)!important;
        padding: 12px 8px !important;
    }
}

