@use '@angular/material' as mat;
@mixin filter-dialog-theme($theme) {
  $primary:       map-get($theme, primary);
  $accent:        map-get($theme, accent);
  $warn:          map-get($theme, warn);
  $background:    map-get($theme, background);
  $foreground:    map-get($theme, foreground);

  .filter-dialog {
    
    .mat-dialog-content{
      .filters-bar-container {
        overflow-y: auto;
           
      .zui-filters-card {
        @include mat.elevation(0);
        padding: 0;
        display: flex;
        flex-flow: column-reverse;
        gap: 1rem;
      }

      zui-asset-filter,
      zui-search-bar,
      zui-zone-filter,
      zui-asset-properties-filter,
      .mat-form-field {
          max-width: 100%;
          min-width: 100%;
      }
      
      app-dropdown,
      .custom-dropdown-class {
        max-width: 100%;
        min-width: 100%;
      }
    }
  }
}
}
