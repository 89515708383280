@import "@angular/material/theming";

@import "../driver-list/driver-list.theme";

@mixin trip-segment-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  @include driver-list-theme($theme);

  div.leaflet-bottom.leaflet-center {
    top: 90%;
    position: absolute;
    pointer-events: none;
  }

  .trip-segment-container {

    .trip-segment-card {
      box-shadow: none;
      border-radius: 0;
      cursor: pointer;
    }

    .highlighted {
      background-color: mat-color($accent, 100);
    }

    .loader {
      margin-bottom: 0px;
    }

    .trip-segment-caption {
      @include mat-typography-level-to-styles($zonar-default-typography, caption);
      text-transform: uppercase;
    }

    p {
      margin: 0;
    }

    .trip-segment-start-address {
      margin-bottom: 1em;
    }

    .time-since-previous-trip {
      margin-left: 1.5em;
      margin-right: 1.5em;
      padding-top: 1em;
      padding-bottom: 1em;
      border-bottom: 1px solid mat-color($primary, default);
      border-top: 1px solid mat-color($primary, default);
    }


    .trip-duration-container {
      padding-bottom: 1em;
    }

    .origin-icon-container,
    .destination-icon-container {
      margin: 0 .75em;
      min-width: 0.5em;
      background: mat-color($accent, default);
      border-radius: 1em 1em 0 0;
      position: relative;

      .trip-segment-icon {
        position: absolute;
        left: -.25em;
        width: 1em;
      }

      .trip-duration-icon {
        width: 24px;
        height: 24px;
        position: absolute;
        left: -.5em;
        top: -.3em;
      }
    }

    .origin-icon-container {
      background: mat-color($accent, default);
    }

    .destination-icon-container {
      background: none;
    }
  }
}