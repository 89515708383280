@mixin dropShadow($radius, $opacity) {
  -webkit-filter: drop-shadow( 1px 2px $radius rgba(112, 112, 112, $opacity));
  filter: drop-shadow( 1px 2px $radius rgba(112, 112, 112, $opacity));
}

@mixin rotation($deg) {
  transform: rotate($deg);
}
@mixin textOutline() {
  text-shadow: 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff,0 0 3px #fff
}

@mixin markerSizing($size) {
  width: $size;
  height: $size;
}

@mixin setMarginLeft($amt) {
  margin-left: ($amt);
}
