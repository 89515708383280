@use '@angular/material' as mat;
@import '@zonar-ui/material/zonar-ui-material.theme';

@mixin search-control-theme($theme) {
    $primary:       map-get($theme, primary);
    $accent:        map-get($theme, accent);
    $warn:          map-get($theme, warn);
    $background:    map-get($theme, background);
    $foreground:    map-get($theme, foreground);
   
    .leaflet-control {
        &.leaflet-control-search { 
            a {
                width: min-content;
            }
            
            zui-icon{
                color: mat.get-color-from-palette($accent);
            }
    
            zui-icon[icon=close] {
                color: mat.get-color-from-palette($foreground, text);

                &:hover {
                    cursor: pointer;
                }
            }

            .search-container {
                @include mat.elevation(5);
                background: mat.get-color-from-palette($background, card);
                border-radius: 0.25em;
                max-width: 20rem;
                z-index: 1000;

                .search-form {
                    padding: 0 0.8rem;
                    padding-left: 0.75rem;

                    .mat-form-field {
                        padding-top: .35rem;
                        width: 20rem;

                        .mat-form-field-wrapper {
                            padding-bottom: 0.4rem;
                        
                            .mat-form-field-infix {
                                border-top: 0;
                                padding: 0.25rem;

                                .mat-form-field-label-wrapper {
                                    left: 0.5rem;
                                }
                            }
                        }

                        .mat-form-field-underline {
                            top: 1.5rem;
                        }
                    }
                }

                .search-results {
                    padding: 1rem;

                    .search-category {
                        margin-bottom: 1rem;
                        &:last-of-type {
                            margin-bottom: 0;
                        }

                        .category-heading{
                            @include mat-typography-level-to-styles($zonar-default-typography, body-2);
                            margin-bottom: 0.75rem;
                        }
    
                        .result-label {
                            margin-left: 1rem;

                            &:not(:last-child) {
                                margin-bottom: 0.5rem;
                            }
                            
                            a {
                                @include mat-elevation(0);
                                @include mat-typography-level-to-styles($zonar-default-typography, body-1);
                                text-align: left;
                                color:mat.get-color-from-palette($accent);
                                text-decoration: underline;
                            }
                        }
    
                        .no-results-text {
                            margin-left: 1rem;
                        }
                    }
                }
            }
        }
    }
}

@mixin search-control-mobile-theme($theme) {
    $primary:       map-get($theme, primary);
    $accent:        map-get($theme, accent);
    $warn:          map-get($theme, warn);
    $background:    map-get($theme, background);
    $foreground:    map-get($theme, foreground);
   
    .leaflet-control {
        .search-container {
            max-width: 15rem;

            .search-form {                
                .mat-form-field {
                    padding-top: 0.7rem;
                    width: 10rem;
    
                    .mat-form-field-wrapper {
                        padding-bottom: 0.7rem;
                    }
                }
            }
        }
    }
}
