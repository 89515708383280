@mixin view-switcher-theme($theme) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $accent: map-get($theme, accent);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .view-switcher-container {
    height: 2.1rem;

    .mat-button-toggle-group {
      border: 2px solid mat-color($accent, default);
      border-radius: 4px;
      height: 100%;

      .mat-button-toggle-label-content {
        line-height: 100%;
        padding: 0;
      }

      mat-button-toggle {
        @include mat-typography-level-to-styles($zonar-default-typography, body-1);
        font-weight: bold;
      }

      .mat-button-toggle-button {
        height: 100%;
        padding: 0 1rem;
      }

      .mat-button-toggle-checked {
        background: mat-color($accent, default);
        color: mat-color($zonar-grey, 100);

        &:hover {
          background: mat-color($accent, darker);
        }
      }

      button#mat-button-toggle-1-button {
        border-right: 1px solid mat-color($accent, default);
      }

      button#mat-button-toggle-2-button {
        border-left: 1px solid mat-color($accent, default);
      }
    }
  }
}
