@use '@angular/material' as mat;
@mixin asset-io-theme($theme) {
    $primary:       map-get($theme, primary);
    $accent:        map-get($theme, accent);
    $warn:          map-get($theme, warn);
    $background:    map-get($theme, background);
    $foreground:    map-get($theme, foreground);

    .asset-io-badge {
        margin-top: 0.25rem;
        background-color: mat.get-color-from-palette($accent, 200);
        border-radius: .25em;
        @include mat.typography-level($zonar-default-typography, caption);
        font-weight: bolder;
        text-transform: uppercase;
        padding: 0.05em 0.4em;
        width: fit-content;
    }

    .asset-io-details {

        .asset-io-icon {
            color: mat.get-color-from-palette($primary, default);
            margin-bottom: 0.5em;
        }

        .asset-io-label {
            color: mat.get-color-from-palette($primary, default);
            @include mat.typography-level($zonar-default-typography, body-1);
            margin-bottom: 0.7em;
        }
    }

}
