@import "@angular/material/theming";

@mixin date-picker-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .date-picker-container {
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;

    .date-picker-form {
      .date-picker-form-field {
        .mat-form-field-wrapper {
          padding: 0;
          margin: 0;

          .mat-form-field-infix {
            padding: 0;

            .mat-input-element {
              margin-bottom: .25em;
            }
          }
        }
      }

      .button-container {
        .mat-stroked-button {
          padding: 0;
          width: 3em;
          line-height: 100%;
          min-width: revert;
          height: 2.2rem;

          &>.dec_button {
            border: 1px solid green;
          }

          &>.inc-button {
            border: 1px solid red;
          }
        }
      }
    }
  }
}
