.loading-app-spinner {
    background: url('/../assets/images/loading-spinner.gif') no-repeat;
    height: 107px;
    width: 103px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    z-index: -1;
}
