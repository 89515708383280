@use '@angular/material' as mat;

@mixin nearby-assets-list-theme($theme) {
  $primary: map-get($theme, primary);
  $warn: map-get($theme, warn);
  $accent: map-get($theme, accent);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-chip.mat-focus-indicator.zonar-loading-spinner.mat-primary.mat-standard-chip {
    // the progress spinner theme scss already has this, but the css stubbornly wouldn't carry over for reasons unknown so I had to repeat the css here
    background-color: mat.get-color-from-palette($background, background);
    @include mat.elevation(3);
    margin-left: 5em;
    margin-bottom: 0.5rem
  }

  .nearby-asset-list-container {
    padding-top: 1rem;
    padding-bottom: 0;

    button.find-nearby-assets-button {
      // @TODO: fix this so button fills space between margins without
      // messing up (nearby) asset list cards
      width: 88%;
      margin: 1.33em;
    }

    .asset-list-title {
      padding-top: 0;
    }

    .no-nearby-assets-message-container {
      background-color: mat.get-color-from-palette($accent, 200);
      border-radius: .25em;
      padding: .6em;
      margin: 1.33em;
    }
  }
}
