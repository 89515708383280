@use '@angular/material' as mat;

@import '../asset-list-info/asset-list-info.component.theme';
@import '../dialog/dialog.theme';

@mixin asset-list-theme($theme) {
  $primary:       map-get($theme, primary);
  $accent:        map-get($theme, accent);
  $warn:          map-get($theme, warn);
  $background:    map-get($theme, background);
  $foreground:    map-get($theme, foreground);

  @include asset-list-info-theme($theme);
  @include dialog-theme($theme);

  // this removes the max-height that Angular adds to the listbox for sorting options.
  div[role=listbox]{
    max-height: initial;
  }
  .asset-list-container {

    .asset-list-scroll-container {
      height: 100%;
      width: 100%;
      overflow-y: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  /* Internet Explorer 10+ */

      &::-webkit-scrollbar { /* WebKit */
          width: 0;
          height: 0;
      }
    }

    .asset-list-title{
      padding-left: 1rem;
      padding-top: 0.5rem;
      margin-bottom: 0;

      h3{
        margin-bottom: 0;
      }

      .count{
        padding-left: 0.5rem;
      }
    }

    .sorting-select {
      padding-left: 1rem;

      .sorting-select-label {
        white-space: nowrap;
      }

      .mat-form-field-wrapper {
        padding-bottom: 0;
        width: 9rem;

        .mat-form-field-infix {
          border: 0;
        }

        .mat-select-value,
        .mat-select-arrow {
          color: mat.get-color-from-palette($accent, default);
        }
      }
    }

    .no-results-text {
      margin-left: 1rem;
    }
    
    .asset-list-item-card{
      // This px must match the itemSize set in asset-list.component for best scroll performance.
      // Then, subtract the top and bottom padding of the mat-card which contains it or it will look weird.
      min-height: calc(89px - 2rem);

      &:hover{
        transition: background 0.3s;
        cursor: pointer;
        background: mat.get-color-from-palette($primary, 200);
      }

      .asset-list-item-name-driver-container{
        margin-bottom: 0.432rem;
        white-space: nowrap;
      }

      .asset-list-heading{
        @include mat.typography-level($zonar-default-typography, subheading-1);
        // locally overriding size since we don't seem to have a sufficiently small heading.
        font-size: 1em;
        overflow-x: clip;
        text-overflow: ellipsis;
      }

      .asset-list-subtext{
        @include mat.typography-level($zonar-default-typography, caption);
        color: mat.get-color-from-palette($primary, default);
        overflow-x: clip;
        text-overflow: ellipsis;
      }

      .missing-gps-data {
        background-color: mat.get-color-from-palette($warn, 200);
        border-radius: .25em;
        @include mat.typography-level($zonar-default-typography, caption);
        font-weight: bolder;
        padding: 0.05em 0.4em;
        width: fit-content;
      }

      .asset-list-sidebar {
        @include mat.typography-level($zonar-default-typography, caption);
        color: mat.get-color-from-palette($primary, default);
      }

      .asset-list-state-of-charge {
        .mat-icon {
          height: 20px;
          width: 20px;
        }
        .asset-list-sidebar {
          margin-top: 0.2rem;
        }
      }
    }
  }
}
