@use '@angular/material' as mat;
@mixin locate-control-theme($theme) {
    $primary:       map-get($theme, primary);
    $accent:        map-get($theme, accent);
    $warn:          map-get($theme, warn);
    $background:    map-get($theme, background);
    $foreground:    map-get($theme, foreground);

    .leaflet-control {
        &.leaflet-control-locate {
            // Default
            a {
                color: mat.get-color-from-palette($primary, default, 0.85);
                span {
                    display: none;
                    visibility: hidden;
                }
                &:after {
                    font-family: 'Material Icons';
                    content: 'gps_not_fixed';
                    display: block;
                    height: inherit;
                    padding: 0.1rem;
                }
            }

            // Requesting
            &.requesting {
                a {
                    color: mat.get-color-from-palette($accent);

                    &:after {
                        content: 'gps_not_fixed';
                    }
                }
            }

            // Active
            &.active {
                a {
                    color: mat.get-color-from-palette($accent, 500);

                    &:after {
                        content: 'gps_fixed';
                    }
                }
            }

            // Active Following
            &.active.following {
                a {
                    color: mat.get-color-from-palette($accent);

                    &:after {
                        content: 'gps_fixed';
                    }
                }
            }
        }
    }
}

@mixin locate-control-mobile-theme($theme) {
    $primary:       map-get($theme, primary);
    $accent:        map-get($theme, accent);
    $warn:          map-get($theme, warn);
    $background:    map-get($theme, background);
    $foreground:    map-get($theme, foreground);

    .leaflet-control {
        &.leaflet-control-locate {
            a {
                &:after {
                    padding: 0.4rem;
                }
            }
        }
    }
}
