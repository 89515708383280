@use '@angular/material' as mat;
@import "~@runette/leaflet-fullscreen/dist/leaflet.fullscreen.css";
@import '~leaflet.locatecontrol/dist/L.Control.Locate.css';

@import 'controls/follow-asset-control/follow-asset-control.theme';
@import 'controls/incident-control/incident-control.theme';
@import 'controls/search-control/search-control.theme';
@import '../timeline-control/timeline-control.theme';
@import 'controls/map-options/map-options.theme';
@import 'controls/fullscreen-control/fullscreen-control.theme';
@import 'controls/locate-control/locate-control.theme';
@import 'controls/zoom-control/zoom-control.theme';
@import 'controls/attribution-control/attribution-control.theme';
@import 'controls/view-entire-trip-control/view-entire-trip-control.theme';
@import 'marker.theme';

@mixin map-component-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .map-container {
    height: 100%;
    width: 100%;
    overflow: hidden;

    // Leaflet map container styles
    .leaflet-container {
      @include mat.typography-level($zonar-default-typography, body-1);

      height: 100%;
      width: 100%;
      position: inherit;
      margin: 0 auto;

      .leaflet-pane.leaflet-marker-pane {
        .path-marker-container {
          div .path-marker-container {
            height: 1.25em;

            img {
              position: relative;
              transform-origin: top center;
            }
          }
        }
      }

      .leaflet-popup-content-wrapper {
        border-radius: 3px;

        .leaflet-popup-content {
          margin: 12px 24px 12px 12px;

          p {
            margin: 12px 0;

            span {
              vertical-align: middle;
              padding-right: 5px;
            }
          }

          .address-text {
            margin: 0;
          }

          .tooltip-icon {
            margin-left: 0.25em;
            color: mat.get-color-from-palette($accent, default);
            position: relative;
            cursor: pointer;
          }

          .tooltip-icon .tooltip-text {
            visibility: hidden;
            width: 250px;
            @include mat.typography-level($zonar-default-typography, caption);
            background-color: rgba(89, 91, 97, 0.808);
            color: #fff;
            border-radius: 6px;
            padding: 5px;

            /* Position the tooltip */
            position: absolute;
            z-index: 1;
          }

          .tooltip-icon:hover .tooltip-text {
            visibility: visible;
          }
        }
      }

      .incident-icon-div {
        .incident-icon-inner-div {
          border-radius: 50% 50% 50% 0;
          transform: translate(-9px, -30px) rotate(-45deg);
          width: 30px;
          height: 30px;
  
          // TODO: we don't pass gun-metal or critical in the zonar themes from PL, so hardcoded hex values for now
          &.incident-low {
            background-color: #49555E;
          }
  
          &.incident-minor {
            background-color: #49555E;
          }
  
          &.incident-major {
            background-color: #F3AF3A;
          }
  
          &.incident-critical {
            background-color: #CE0100;
          }
          
          &::before {
            position: absolute;
            left: 0;
            content: '';
            width: 24px;
            height: 24px;
            margin: 3px 0 0 3px;
            border-radius: 50%;
            background-color: white;
          }

          i.material-icons {
            transform: rotate(45deg);
            margin: 3px;
          }
        }
      }

      .incident-polyline {
        stroke-width: 10px;
        stroke-opacity: .6;

        &.incident-polyline-low {
          stroke: #49555E;
          stroke-opacity: .5;
        }

        &.incident-polyline-minor {
          stroke: #49555E;
          stroke-opacity: .5;
        }

        &.incident-polyline-major {
          stroke: #F3AF3A;
        }

        &.incident-polyline-critical {
          stroke: #CE0100;
        }
      }

      .leaflet-popup.incident-popup {
        p {
          margin: .25em 0;
        }
        p.incident-summary {
          @include mat-typography-level-to-styles($zonar-default-typography, body-2);

          i.material-icons {
            vertical-align: middle;
          }
        }

        p.incident-details {
          @include mat-typography-level-to-styles($zonar-default-typography, body-1);
        }
        
        p.incident-no-polyline {
          @include mat-typography-level-to-styles($zonar-default-typography, caption);
        }

        &.incident-popup-top {
          bottom: .25em !important;
        }

        &.incident-popup-bottom {
          top: .5em !important;
        }

        .leaflet-popup-tip-container {
          visibility: hidden;
        }
      }

      .selected-path-border {
        stroke: mat.get-color-from-palette($zonar-grey, 100);
        stroke-width: 12px;
      }

      .selected-path-segment {
        stroke: mat.get-color-from-palette($accent, default);
        stroke-width: 8px;
      }

      .deselected-path-segment {
        stroke: mat.get-color-from-palette($primary, 500);
        stroke-width: 8px;
      }
    }

    // Global control styles
    .leaflet-control {
      background-color: inherit;
      border-radius: inherit;
      border: none;
      box-shadow: none;

      a {
        @include mat.typography-level($zonar-default-typography, subheading-1);
        @include mat.elevation(3);
        background-color: mat.get-color-from-palette($background, card);
        color: mat.get-color-from-palette($accent);
        height: 1.5em;
        width: 1.5em;
        text-decoration: none;

        &.leaflet-bar-part {
          &:first-child {
            border-top-left-radius: 50%;
            border-top-right-radius: 50%;
          }

          &:last-child {
            border-bottom-left-radius: 50%;
            border-bottom-right-radius: 50%;
          }
        }

        &.leaflet-control-pill {
          @include mat.typography-level($zonar-default-typography, body-2);
          border-radius: 1.5rem;
          padding: 1.2rem 1.25rem;
          padding-left: 0.75rem;
          min-width: min-content;
          
          zui-icon {
            transform: scale(95%);
            margin-right: 0.2rem;
          }
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    // Individual control styles
    @include marker-theme($theme);
    @include incident-control-theme($theme);
    @include search-control-theme($theme);
    @include timeline-control-theme($theme);
    @include follow-asset-control-theme($theme);
    @include map-options-theme($theme);
    @include fullscreen-control-theme($theme);
    @include locate-control-theme($theme);
    @include zoom-control-theme($theme);
    @include attribution-control-theme($theme);
    @include view-entire-trip-control-theme($theme);

    // Align the search and incident buttons horizontally, left of the layers control
    .leaflet-control {
      &.leaflet-control-incident {
        display: table-cell;
        order: 2;
      }
    }

    &.three-control-slot {
      .leaflet-control-search {
        display: table-cell;
        order: 1;
      }
    }

    &.two-control-slot {
      .leaflet-control-search {
        position: fixed;
        right: 7rem;
      }
    }

  }

  .app-container-mobile {
    .map-container {
      @include search-control-mobile-theme($theme);
      @include incident-control-mobile-theme($theme);
      @include locate-control-mobile-theme($theme);

      .leaflet-control {
        a {
          border-radius: 50%;
          height: 3rem;
          max-width: 3rem;
          min-width: 3rem;
        }

        &.leaflet-control-incident {
          right: 4rem;
        }

        &.leaflet-control-fullscreen,
        &.leaflet-control-zoom,
        &.timeline-control-container {
          display: none;
          visibility: hidden;
        }
      }

      &.three-control-slot {
        .leaflet-control-search {
          right: 8rem;
        }
      }

      &.two-control-slot {
        .leaflet-control-search {
          right: 4rem;
        }
      }

    }
  }

  // This is a horrific hack.
  .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane .mat-select-panel.ng-animating {
    display: initial !important;
  }

  span [class*="cypress-"] {
    pointer-events: none;
  }

  // this grabs a leaflet divIcon created in src/app/modules/zones/services/leaflet-zone.service.ts
  .gtcx-zone-name {
    label {
        cursor: pointer;
        display: block;
        font-size: 1rem;
        font-weight: 800;
        width: 12vw;
        position: relative;
        right: 2vw;
        bottom: 0.5vw;
        text-shadow:
            -1px -1px 0  mat.get-color-from-palette($primary, 100),
            0   -1px 0  mat.get-color-from-palette($primary, 100),
            1px -1px 0  mat.get-color-from-palette($primary, 100),
            1px  0   0  mat.get-color-from-palette($primary, 100),
            1px  1px 0  mat.get-color-from-palette($primary, 100),
            0    1px 0  mat.get-color-from-palette($primary, 100),
            -1px  1px 0  mat.get-color-from-palette($primary, 100),
            -1px  0   0  mat.get-color-from-palette($primary, 100),
      }
  }

}
