html {
    height: -webkit-fill-available;
    box-sizing: border-box;
}

body {
    margin: 0;
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
    padding: 0;
}

html,
body {
    display: block;
    height: 100%;
    margin: 0;
    padding: 0;
}

// *,
*:before,
*:after {
    box-sizing: inherit;
}
