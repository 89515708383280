@use '@angular/material' as mat;

@mixin incident-control-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .leaflet-control {
    &.leaflet-control-incident {
      a.leaflet-control-incident-button {
        width: min-content;
      }
    }
  }
}

@mixin incident-control-mobile-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .leaflet-control {
    &.leaflet-control-incident {
      
    }
  }
}


