@use '@angular/material' as mat;
@import '../shared/components/open-events-container/open-events-container.theme';

@mixin events-theme($theme) {
    $primary:       map-get($theme, primary);
    $accent:        map-get($theme, accent);
    $warn:          map-get($theme, warn);
    $background:    map-get($theme, background);
    $foreground:    map-get($theme, foreground);

    @include open-events-container-theme($theme);

    .event-badge {
      .lozenge {
        border-radius: .25em;
        @include mat.typography-level($zonar-default-typography, caption);
        font-weight: bolder;
        padding: 0.1em 0.5em 0.08em 0.5em;
        width: fit-content;
        text-transform: uppercase;
        margin-top: 0.05em;
      }
      .error {
        background-color: mat.get-color-from-palette($warn, 500);
        color: mat.get-color-from-palette($primary, 100);
      }
      .warning {
        background-color: mat.get-color-from-palette($warn, 200);
        color: mat.get-color-from-palette($primary, 900);
      }
      .information {
        background-color: mat.get-color-from-palette($accent, 200);
        color: mat.get-color-from-palette($primary, 900);
      }
    }
}
