@use '@angular/material' as mat;
@mixin view-entire-trip-control-theme($theme) {
    $primary:       map-get($theme, primary);
    $accent:        map-get($theme, accent);
    $warn:          map-get($theme, warn);
    $background:    map-get($theme, background);
    $foreground:    map-get($theme, foreground);

    .leaflet-control {
        &.leaflet-control-view-entire-trip{
            @include mat.elevation(3);
            border-radius: 1.5em;
            padding: .75em;
            width: 10em;
            height: 1em;
            text-align: center;
            display: flex;
            justify-content: space-around;
            align-items: center;
            position: absolute;
            right: 60%;
            bottom: 40%;

            &:hover {
                cursor: pointer;
            }

            p {
                margin: 0;
            }

            &.view-entire-trip {
              background: mat.get-color-from-palette($accent, 400);
              color: mat.get-color-from-palette($primary, 100);
            }

            &.hidden {
                visibility: hidden;
            }
        }
    }
}
