@use '@angular/material' as mat;
@import "@angular/material/theming";
@import "../shared/components/view-switcher/view-switcher.theme";
@import "components/path-detail/path-detail.theme";

@mixin asset-history-theme($theme) {
  $primary:       map-get($theme, primary);
  $accent:        map-get($theme, accent);
  $warn:          map-get($theme, warn);
  $background:    map-get($theme, background);
  $foreground:    map-get($theme, foreground);

  @include view-switcher-theme($theme);
  @include path-detail-theme($theme);

  // this is the background-color that is hard-coded in ngx-skeleton-loader
  // we do not have a zonar-ui color that is similar enough that an observant user would not notice the difference
  // https://github.com/willmendesneto/ngx-skeleton-loader/blob/main/projects/ngx-skeleton-loader/src/lib/ngx-skeleton-loader.scss#L12
  $ngx-skeleton-loader-background-color: rgb(239, 241, 246);

  app-path-detail {
    height: 100%;
    overflow: auto;
  }


  .view-switcher-toggle-group {
    background: mat.get-color-from-palette($accent, primary);
  }

  .asset-title-label-container {

    .activity-icon {
      color: mat.get-color-from-palette($primary, default);
    }

    .activity-value {
      color: mat.get-color-from-palette($primary, default);
      @include mat.typography-level($zonar-default-typography, body-1);
      font-weight: bold;
    }

    .asset-name {
      .asset-name-skeleton-container {
        max-height: 1.25rem;
        min-width: 9rem;
        overflow: hidden;
      }
    }
  }

  // TODO: extract this as described in https://jira.zonarsystems.net/browse/ZTT-851
  .loading-skeleton {
    display: inline-block;
    position: relative;
    overflow: hidden;
    background-color: $ngx-skeleton-loader-background-color;
    min-height: 1em;
    min-width: 80%;


    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
                      90deg,
                      rgba(#fff, 0) 0,
                      rgba(#fff, 0.2) 20%,
                      rgba(#fff, 0.5) 60%,
                      rgba(#fff, 0)
      );
      animation: shimmer 2s infinite;
      content: '';
    }

    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }
}
