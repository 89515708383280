@use '@angular/material' as mat;
@mixin attribution-control-theme($theme) {
    $primary:       map-get($theme, primary);
    $accent:        map-get($theme, accent);
    $warn:          map-get($theme, warn);
    $background:    map-get($theme, background);
    $foreground:    map-get($theme, foreground);

    .leaflet-control {
        &.leaflet-control-attribution {
            background: mat.get-color-from-palette($background, card, .7);
            color: mat.get-color-from-palette($foreground, text);
            padding: 2px 6px;
    
            a {
                @include mat.elevation(0);
                background-color: transparent;
                border-radius: 0;
                color: mat.get-color-from-palette($accent, lighter);
                font-size: inherit;
                text-decoration: none;
                text-align: inherit;
            }
        }
    }

}