@use '@angular/material' as mat;
@import '@zonar-ui/material/zonar-ui-material.theme';
@mixin timeline-control-theme($theme) {
    $primary:       map-get($theme, primary);
    $accent:        map-get($theme, accent);
    $warn:          map-get($theme, warn);
    $background:    map-get($theme, background);
    $foreground:    map-get($theme, foreground);
    @include zonar-ui-notifications-theme($theme);

     div.leaflet-bottom.leaflet-center {
          width:calc(100% - 30em);
          transform: translate(24.7em, 0%);
     }

    .leaflet-control {
        &.timeline-control-container{
          @include mat.elevation(3);
          border-radius: 0.25em;
          bottom: 3em;
          padding: 0.75em;
          width: 100%;
          text-align: center;
          align-items: center;
          position: absolute;
          background: mat.get-color-from-palette($background, card);
        }
        &.hidden {
          visibility: hidden;
        }
        .start{
          text-align: left;
        }
        .end{
          text-align: right;
        }
        .time{
            height: 1em;
        }
        .time-line-point{
          cursor: pointer;
          position: relative;
          width: 100%;
        }
        .time-line-bar{
          height: 1.25em;
        }
        .in-motion{
          background-color: mat.get-color-from-palette($mat-green, 500);
        }
        .powered-off{
          background-color: mat.get-color-from-palette($primary, lighter);
        }
        .idle{
          background-color: mat.get-color-from-palette($mat-yellow, 500);
        }
        .view-trip {
          border-radius: 1.5em;
          color: white;
          padding: 0 12px 0 12px;
          span{
            font-size: 0.9vw;
          }
        }
        .skeleton-container{
          max-height: 180px;
        }
        .path-skeleton-frame{
          padding-left: 1em;
          padding-right: 1em;
        }

        .skeleton-container {
          margin: 0.5em 1.5em;

          .loader {
            margin-bottom: 0;
          }
        }
        .timestamp{
          text-align: center;
          position: fixed;
        }
        .on-hover{
            text-align: center;
            position: absolute;
            height: 1.5em;
            width: 1.5em;
            z-index: 9999;
            scale: 0.7;
            opacity: 1;
            top: 35%; // equivalent to its scale
            right: 50%;
            transform: translate(50%,-50%);
            pointer-events: none;
        }
      .show-time{
          opacity: 1;
          transition: 0.5s;
      }
      .hide-time{
          opacity: 0;
          transition: 0.5s;
      }
      @if $theme==$zonar-vdo-theme {
        .in-motion{
          background-color: $vdo-red;
        }
        .idle{
          background-color: $vdo-yellow;
        }
      }
    }
}