@use '@angular/material' as mat;

@mixin map-header-theme($theme) {
  $primary:       map-get($theme, primary);
  $accent:        map-get($theme, accent);
  $warn:          map-get($theme, warn);
  $background:    map-get($theme, background);
  $foreground:    map-get($theme, foreground);

  .search-container-option {
    pre {
      @include mat.typography-level($zonar-default-typography, body-1);
    }

    span.material-icons {
      margin-right: .25em;
    }
  }

  .map-header {
    .map-header-content {

      padding: .55rem .55rem .55rem 1rem;

      .close-btn {
        top: 2px; // This must match the border width of the view switcher, to keep the button centered with it vertically
        padding: 0 .5rem;
        margin: 0 .5rem;

        span {
          @include mat.typography-level($zonar-default-typography, body-2);
        }
      }
    }
  }

  // this fixes an issue in safari, in which .map-header-mobile will not render
  // with position=fixed if any parent container has overflow=auto
  .mat-drawer-container,
  .mat-drawer,
  .mat-drawer {
    overflow: visible;
  }

  @media only screen and (min-width: 600px) {
    .mat-drawer-content{
      overflow: visible;
    }
  }
  


  .map-header-mobile {
    align-self: flex-end;
    pointer-events: auto;

    .map-header-content {

      .navigation-container {
        padding: 1rem;
        width: 100vw;
        border-top: 1px solid mat.get-color-from-palette($primary, lighter);
      }

      .close-btn {
        top: 2px;
        padding: 0 .5rem;
        margin: 0 .5rem;

        span {
            @include mat.typography-level($zonar-default-typography, body-2);
        }
      }

      .mat-button-focus-overlay {
        background-color: transparent;
      }
    }
  }
}
