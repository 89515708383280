@use '@angular/material' as mat;
@import "../../../../../styles/utils";

@mixin textOutline() {
  text-shadow: 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff, 0 0 3px #fff,0 0 3px #fff
}

@mixin markerSizing() {
  height: 1.5rem;
  min-width: 1.5rem;
  width: auto;
}

@mixin markerFlex() {
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-basis: content;

}

@mixin markerStyles($primary, $background) {
  background: mat.get-color-from-palette($background, card);
  border: 0.125rem solid mat.get-color-from-palette($primary, default);
  border-radius: 1.175rem;
  padding: 0.05rem;
}

$marker-zIndex: 102;

@mixin marker-theme($theme) {
  $primary:       map-get($theme, primary);
  $accent:        map-get($theme, accent);
  $warn:          map-get($theme, warn);
  $background:    map-get($theme, background);
  $foreground:    map-get($theme, foreground);

  @keyframes growIcon {
    0% {
      @include markerSizing();
      @include markerStyles($primary, $background);
      transform: scale(1);
    }
    100% {
      @include markerSizing();
      @include markerStyles($primary, $background);
      transform: scale(1.5);
    }
  }

  .path-marker-container {
    width: 0px;
    height: 0px;
  }

  .path-marker-icon {
    position: relative;
    top: -.25em;
    right: .25em;
    width: 1.25em;
    height: 1.25em;
  }

  .path-marker-in-motion,
  .path-marker-idling {
    animation: growIcon 0.1s forwards;
  }

  .map-marker {
    margin: 0;
    padding: 0;

    .map-marker-container {
      @include markerFlex();
      @include markerStyles($primary, $background);
      @for $i from 0 through 200 {
        &.map-marker-reposition-#{$i} {
          @include setMarginLeft(#{$i}px);
        }
      }
    }

    &.highlight {
      .map-marker-container {
        animation: growIcon 0.1s forwards;
      }
    }

    .map-marker-icon {
      @include markerSizing();
      align-self: center;
      z-index: $marker-zIndex;

      @for $i from 0 through 15 {
        &.deg-#{$i * 225} {
          @include rotation(#{$i * 22.5}deg);
        }
      }
    }

    .map-marker-alert-icon {
      position: absolute;
      top: -1rem;
      right: -1rem;
      z-index: 1000;
      width: 1.875rem;
      height: 1.875rem;
    }

    .map-marker-label-container {
      flex-flow: column;
      justify-content: flex-start;
      justify-items: flex-start;
      flex-basis: content;
      margin-top: 0.1rem;
      padding-left: 0.1rem;
      padding-right: 0.75rem;
      z-index: $marker-zIndex;

      .map-marker-label {
        @include mat.typography-level($zonar-default-typography, body-2);
        white-space: nowrap;
      }

      .map-marker-subtitle {
        @include mat.typography-level($zonar-default-typography, caption);
        color: mat.get-color-from-palette($foreground, text);
        font-weight: bold;
        white-space: nowrap;
        z-index: $marker-zIndex;
      }
    }
  }

  .marker-cluster-container {
    .marker-cluster-icon {
      @include textOutline();
      @include mat.typography-level($zonar-default-typography, body-2);
      position: relative;
      height: 29px;  // these are absolute sizes now, as are the svg marker icons.
      width: 29px;   // TODO: (maybe) make markers and cluster icons dynamically sized.
      line-height: 29px;
      text-align: center;
      background-color: mat.get-color-from-palette($background, card);
      border-radius: 50%;
      border: 0.125rem solid mat.get-color-from-palette($primary, default);
    }
  }
}
