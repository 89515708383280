@use '@angular/material' as mat;
@mixin zoom-control-theme($theme) {
    $primary:       map-get($theme, primary);
    $accent:        map-get($theme, accent);
    $warn:          map-get($theme, warn);
    $background:    map-get($theme, background);
    $foreground:    map-get($theme, foreground);
    
    .leaflet-control {
        &.leaflet-control-zoom {
            a {
                &:first-child,
                &:last-child {
                    border-top-left-radius: 50%;
                    border-top-right-radius: 50%;
                    border-bottom-left-radius: 50%;
                    border-bottom-right-radius: 50%;
                }

                &:first-child {
                    margin-bottom: 10px;
                }

                &.leaflet-disabled {
                    color: mat.get-color-from-palette($foreground, disabled-button);
                }
            }
        }
    }
}
