@use '@angular/material' as mat;
@import '../../../events/components/expandable-event-details/expandable-event-details.theme';

@mixin open-events-container-theme($theme) {
  $primary:    map-get($theme, primary);
  $warn:       map-get($theme, warn);
  $accent:     map-get($theme, accent);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  @include expandable-event-details-theme($theme);

  .open-events-container {
    .error-message {
      color: mat.get-color-from-palette($warn);
      display: block;
      width: 60%;
      text-align: center;
      margin: 0 auto;
    }
    border-top: 1px solid mat.get-color-from-palette($primary, lighter);
    cursor: default;

    .title {
      @include mat.typography-level($zonar-default-typography, caption);
    }

    #geocoderLoadingIndicator{
      color: mat.get-color-from-palette($background, lighter);
      width: 18em;
      height: 1em;
    }

    .events {
      a.current-location-link, a.current-location-link:active, a.current-location-link:visited,
      .recent-path-link {
        color: mat.get-color-from-palette($accent, default);
        text-decoration: underline;
        cursor: pointer;
      }
    }

    p.activity-value {
      border-radius: .25em;
      @include mat.typography-level($zonar-default-typography, caption);
      font-weight: bolder;
      padding: 0.1em 0.5em 0.08em 0.5em;
      width: fit-content;
      text-transform: uppercase;
      margin-top: 0.05em;
      background-color: mat.get-color-from-palette($warn, 500);
      color: mat.get-color-from-palette($primary, 100);
    }
  }
}
