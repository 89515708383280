@use '@angular/material' as mat;

@import '../../../shared/components/filter-dialog/filter-dialog.theme';
@import '../../../shared/components/map/map.theme';
@import '../../../shared/components/map-header/map-header.theme';
@import '../../../../modules/mobile/components/map-content-selector/map-content-selector.theme';
@import '../../../shared/components/asset-list/asset-list.theme';
@import '../../../../modules/asset-io/asset-io.theme';
@import '../../../../modules/asset-details-live-container/components/desktop-asset-details/desktop-asset-details.theme';
@import '../../../shared/components/progress-spinner/progress-spinner.theme';
@import '@zonar-ui/searchable-dropdown/zonar-ui-searchable-dropdown.theme';
@import '../../../asset-history/asset-history.theme';
@import '../mobile-location-container/mobile-location-container.theme';
@import '../../../../modules/asset-details-live-container/components/driver-details/driver-details.component.theme.scss';
@import '../../../../modules/events/events.theme';

// magic numbers
$sidenavWidth: 23em; // to maintain sizing of drawer so asset data doesn't stretch it out

@mixin locations-page-theme($theme) {
    $primary:       map-get($theme, primary);
    $accent:        map-get($theme, accent);
    $warn:          map-get($theme, warn);
    $background:    map-get($theme, background);
    $foreground:    map-get($theme, foreground);

    @include map-header-theme($theme);
    @include filter-dialog-theme($theme);
    @include map-component-theme($theme);
    @include asset-list-theme($theme);
    @include asset-io-theme($theme);
    @include selected-asset-theme($theme);
    @include progress-spinner-theme($theme);
    @include zonar-ui-searchable-dropdown-theme($theme);
    @include asset-history-theme($theme);
    @include mobile-location-container($theme);
    @include driver-details-theme($theme);
    @include events-theme($theme);


    .content-pane, .rotate-message-container {
        background-color: mat.get-color-from-palette($background, background);
        z-index: 1; // Needs to be at least 1 layer higher than router-outlet/spinner
    }

    .content-pane {
        height: 100%;
        overflow: auto;
    }

    .app-container-desktop {
        .mat-drawer {
            width: $sidenavWidth;

            .mat-drawer-inner-container {
                overflow: hidden; // This prevents the leftnav from scrolling
            }
        }

        .sidenav-bottom-padded {
            height: 100vh;
        }
    }

    .app-container-mobile {
        @include map-content-selector-theme($theme);

        .filter-chip-mobile-container .filter-chip-list .mat-chip-list-wrapper {
            margin: 0;
            border-top: 1px solid mat.get-color-from-palette($primary, lighter);

            &:empty {
                border-top: none;
            }

            .mat-standard-chip {
                margin: .25em;
            }
        }

        .mobile-date-picker {
          padding-top: 1em;
        }
    }

    .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
        &.overlay-backdrop {
            background-color: mat.get-color-from-palette($primary, default);
            opacity: 0.5;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100vh;
        }
    }

    .activity-icon {
      color: mat.get-color-from-palette($primary, default);
    }
}
