@import "@angular/material/theming";
@import "../../../shared/components/view-switcher/view-switcher.theme";
@import "../date-picker/date-picker.theme";
@import "../trip-segment/trip-segment.theme";
@import "../ztrak-trip-segment/ztrak-trip-segment.theme";

@mixin path-detail-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  @include view-switcher-theme($theme);
  @include date-picker-theme($theme);
  @include trip-segment-theme($theme);
  @include ztrak-trip-segment-theme($theme);

  .skeleton-container{
    max-height: 180px;
  }
  .path-skeleton-frame{
    padding-left: 1em;
    padding-right: 1em;
  }

  .path-detail-container {
    width: 100%;
    padding-top: 1em;
    cursor: default;
    height: 100%;
    overflow: auto;
    padding: 0;
    .title {
      @include mat-typography-level-to-styles($zonar-default-typography, body-1);
      font-weight: bold;
      padding-left: 1em;
    }

    .skeleton-container {
      margin: 0.5em 1.5em;


      .loader {
        margin-bottom: 0px;
      }
    }

    .push-left {
      margin-right: 3%;
    }

    .trip-segment-download-icon {
      cursor: pointer;
    }
  }
}
