
@use '@angular/material' as mat;

@mixin map-options-theme($theme) {
    $primary:       map-get($theme, primary);
    $accent:        map-get($theme, accent);
    $warn:          map-get($theme, warn);
    $background:    map-get($theme, background);
    $foreground:    map-get($theme, foreground);
    .leaflet-control{
        &.map-options {
            order: 999;
            z-index: 999;
            .map-options-popup-panel{
                @include mat.elevation(5);
                background: mat.get-color-from-palette($background, card);
                border-radius: 4px;
            }
        }

        #map-options-menu {
            div {
                border: solid 1px mat.get-color-from-palette($primary, lighter);

                &.border-selected {
                    border: solid 2px mat.get-color-from-palette($accent, default);
                  }
              
            } 

            section {
                border-top: solid 1px mat.get-color-from-palette($primary, lighter);
            }
        }
    }
}