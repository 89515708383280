@use '@angular/material' as mat;

@mixin asset-list-info-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .info-dialog {
    margin-left: auto;
  }

  .info-container {
    min-width: min-content;

    .info-dialog {
      flex-grow: 1;
      h2 {
        @include mat.typography-level($zonar-default-typography, subheading-1);
        font-weight: 400;
      }
      p {
        @include mat.typography-level($zonar-default-typography, body-1);
      }
    }
  }
}
