@use '@angular/material' as mat;
@mixin expandable-event-details-theme($theme) {
  $primary:    map-get($theme, primary);
  $warn:       map-get($theme, warn);
  $accent:     map-get($theme, accent);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .event-details-container {

    cursor: default;

  }
}
