@use '@angular/material' as mat;
@mixin app-theme($theme) {
    $primary:       map-get($theme, primary);
    $accent:        map-get($theme, accent);
    $warn:          map-get($theme, warn);
    $background:    map-get($theme, background);
    $foreground:    map-get($theme, foreground);


    .error-overlay {
        background-color: mat.get-color-from-palette($primary, default);
        opacity: 0.5;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100vh;
    }

    .app-disable {
        pointer-events: none;
    }

    .mat-toolbar.mat-primary.header-bar-sidenav-toolbar {
        background: mat.get-color-from-palette($primary, 50);

        .sidenav-toggle {
          color: mat.get-color-from-palette($primary, 900);
        }
    }

    .mat-drawer:not(.mat-drawer-side) {
        box-shadow: 8px 0px 10px -10px rgba(0,0,0,0.45) !important;
    }
}
