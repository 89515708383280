@mixin fullscreen-control-theme($theme) {
    $primary:       map-get($theme, primary);
    $accent:        map-get($theme, accent);
    $warn:          map-get($theme, warn);
    $background:    map-get($theme, background);
    $foreground:    map-get($theme, foreground);
    
    .leaflet-control {
        &.leaflet-control-fullscreen {
            a {
                background-image: none;
                
                &:after {
                    font-family: 'Material Icons';
                    content: 'fullscreen';
                }
            }
        }
    }

    // Fullscreen escape control
    .leaflet-fullscreen-on {
        .leaflet-control-container {
            .leaflet-control {
                &.leaflet-control-fullscreen {
                    a {
                        &:after {
                            content: 'fullscreen_exit';
                        }
                    }
                }
            }
        }
    }
    
}
