@use '@angular/material' as mat;
@mixin progress-spinner-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);


  .zonar-default-theme .mat-chip.mat-standard-chip.zonar-loading-spinner {
    background-color: mat.get-color-from-palette($background, background);
    @include mat.elevation(3);
  }
}
