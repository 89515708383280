@use '@angular/material' as mat;
@mixin current-activity-theme($theme) {
  $primary:    map-get($theme, primary);
  $warn:       map-get($theme, warn);
  $accent:     map-get($theme, accent);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .current-activity-container {
    .error-message {
      color: mat.get-color-from-palette($warn);
      display: block;
      width: 60%;
      text-align: center;
      margin: 0 auto;
    }
    border-bottom: 1px solid mat.get-color-from-palette($primary, lighter);
    cursor: default;

    .title {
      @include mat.typography-level($zonar-default-typography, subheading-1);
    }

    #geocoderLoadingIndicator{
      color: mat.get-color-from-palette($background, lighter);
      width: 18em;
      height: 1em;
    }

    .activities {
      a.current-location-link, a.current-location-link:active, a.current-location-link:visited,
      .recent-path-link {
        color: mat.get-color-from-palette($accent, default);
        text-decoration: underline;
        cursor: pointer;
      }

      a.current-location-link::after {
        font-family: "Material Icons";
        content: "open_in_new";
      }
    }

    .asset-io-details {
      margin-left: 1rem;
    }

    .stale-data-message-container {
      background-color: mat.get-color-from-palette($accent, 200);
      border-radius: .25em;
      padding: .6em;
      margin: 0 1em 1.5rem;
    }

  }

  .missing-gps-container {
    background-color: mat.get-color-from-palette($warn, 100);
    margin: 1.8em 1em;
    padding: 0.6em;

    .missing-gps-heading {
      background-color: mat.get-color-from-palette($warn, 200);
      border-radius: .25em;
      @include mat.typography-level($zonar-default-typography, caption);
      font-weight: bolder;
      padding: 0.25em 0.5em;
      width: fit-content;
    }

    .missing-gps-message {
      @include mat.typography-level($zonar-default-typography, caption);
      padding-top: 0.8em;
    }
  }
}
